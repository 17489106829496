
















import Component from 'vue-class-component';
import Vue from 'vue';
import {Prop} from 'vue-property-decorator';
import {twoChars} from '@/utils/helpers';

@Component({
  name: 'avatar-video-call',
  filters: {twoChars}
})
export default class AvatarVideoCall extends Vue {
  @Prop({default: 135}) size?: number | string;
  @Prop({default: 'primary'}) bgColor?: string;
  @Prop() imageUrl?: string;
  @Prop() name?: string;
}
