




























import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import InnerChatsList from '@/components/InnerChatsList.vue';
import {Getter} from 'vuex-class';
import DialogCreateNewChat from '@/components/chats/DialogCreateNewChat.vue';
import {profileStore} from '@/store/modules/profile';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from "vue-class-component";

@Component({
  name: 'inner-chats',
  components: {DialogCreateNewChat, InnerChatsList}
})
export default class InnerChats extends mixins(Vue, Notifications) {
  @Prop() type?: string;
  @Prop() subtype?: string;

  showCreateChatDialog: boolean = false
  userId?: string | null = profileStore.t2bUser?.id

  @Getter inner;

  predicatePersonal = (chat) => chat.memberIDs.includes(this.userId);
  predicateAll = (chat) => !chat.memberIDs.includes(this.userId);

  get key() {
    return `${this.type}:${this.subtype}`
  }

  get isAll() {
    return this.subtype === 'all'
  }

  get innerChats() {
    return this.isAll
        ? this.inner.filter(this.predicateAll)
        : this.inner.filter(this.predicatePersonal)
  }

  onChatSelected(chatId) {
    this.$router.push({name: 'inner-chat', params: {chatId}, query: this.$route.query});
  }

  showToast(text) {
    this.showInfo(text);
  }

  showErrorToast(error) {
    this.showIssue(error);
  }

  created() {
    console.log(`InnerChats :: created, ${this.key}/${this.$route.params.chatId}`);
  };

  updated() {
    console.log(`InnerChats :: updated, ${this.key}/${this.$route.params.chatId}`);
  }

  beforeDestroy() {
    console.log('InnerChats :: beforeDestroy');
  }
}
