

























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter, Mutation} from 'vuex-class';
import {pad} from '@/utils/helpers';
import AvatarVideoCall from '@/components/AvatarVideoCall.vue';
import {videoCallStore} from '@/store/modules/video/videoCallStore';
import {profileStore} from '@/store/modules/profile';

@Component({
  name: 'dialog-video-call-full-screen',
  components: {AvatarVideoCall}
})
export default class DialogVideoCallFullScreen extends Vue {

  @Prop({type: Boolean}) value;
  @Prop({type: Number, default: 376}) width;
  @Prop({type: Number, default: 984}) maxWidth;
  @Prop({type: Number, default: 463}) height;
  @Prop({type: Number, default: 840}) maxHeight;

  fullscreen: boolean = false;
  minutes: string = '00';
  seconds: string = '00';
  timeout: NodeJS.Timeout | null = null;

  @Getter videoMinimized!: boolean;
  @Getter videoTotalSeconds!: number;

  @Mutation setVideoTotalSeconds;

  @Action minimizeVideo;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get connected() {
    return videoCallStore.connected
  }

  get dialogWidth() {
    return this.connected ? this.maxWidth : this.width
  }

  get dialogHeight() {
    return this.connected ? this.maxHeight : this.height
  }

  get chatVideoSession() {
    return videoCallStore.currentVideoCallSession
  }

  get session() {
    return videoCallStore.videoSession
  }

  get publisher() {
    return videoCallStore.publisher
  }

  get outgoing() {
    return videoCallStore.outgoingVideoCall
  }

  get incoming() {
    return videoCallStore.incomingVideoCall
  }

  get voiceMuted() {
    return videoCallStore.voiceMuted
  }

  get videoMuted() {
    return videoCallStore.videoMuted
  }

  get title() {
    return this.chatVideoSession && this.chatVideoSession.title
  }

  get callTitle() {
    if (!!this.title) {
      return this.title
    }
    if (this.isActiveChat) {
      return this.chatVideoSession?.customer && this.chatVideoSession.customer.name
    }
    return this.chatVideoSession?.from.uid === this.userId
        ? this.chatVideoSession?.to.name
        : this.chatVideoSession?.from.name
  }

  get membersCount() {
    return this.chatVideoSession && Object.keys(this.chatVideoSession.members).length;
  }

  get userId() {
    return profileStore.t2bUser?.id
  }

  get isActiveChat() {
    return this.chatVideoSession && this.chatVideoSession.type === 4;
  }

  get imageUrl() {
    if (!this.chatVideoSession) {
      return null
    }
    if (this.isActiveChat) {
      const customer = this.chatVideoSession.customer;
      const photoUrl = customer && customer.photoUrl;
      return photoUrl && photoUrl.normal
    }
    const fromId = this.chatVideoSession.from && this.chatVideoSession.from.uid;
    const toId = this.chatVideoSession.to && this.chatVideoSession.to.uid;
    const uid = fromId === this.userId ? toId : fromId
    const member = this.chatVideoSession.members && this.chatVideoSession.members[uid];
    return (member && member.photoUrl) ? member.photoUrl.normal : null;
  }

  get timePassed() {
    return `${this.minutes}:${this.seconds}`;
  }

  get totalSeconds() {
    return this.videoTotalSeconds;
  }

  set totalSeconds(value) {
    this.setVideoTotalSeconds(value);
  }

  onMuteAudio() {
    videoCallStore.toggleAudio()
  }

  onMuteVideo() {
    videoCallStore.toggleVideo()
  }

  onMinimizeVideo() {
    this.minimizeVideo(this.totalSeconds);
  }

  onEndVideoCall() {
    videoCallStore.endVideoCall()
    this.close();
  }

  onAcceptVideoCall() {
    videoCallStore.acceptVideoCall(false)
  }

  onAcceptVoiceCall() {
    videoCallStore.acceptVideoCall(true)
  }

  close() {
    this.show = false;
    this.$emit('close-dialog');
  }

  private startTimer() {
    this.timeout = setInterval(this.setTime, 1000);
  }

  private setTime() {
    this.totalSeconds += 1;
    this.seconds = pad(this.totalSeconds % 60);
    this.minutes = pad(parseInt(String(this.totalSeconds / 60)));
  }

  async created() {
    console.log('VIDEO_CALL => created');
    this.startTimer();
  }

  async beforeDestroy() {
    console.log('VIDEO_CALL => before destroy');
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }
}
