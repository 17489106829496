














































import {Component, Prop, Watch} from 'vue-property-decorator';
import SearchView from '@/components/SearchView.vue';
import ListGroupItem from '@/components/ListGroupItem.vue';
import ContactListItem from '@/components/ContactListItem.vue';
import DialogCreateAssociate from '@/components/DialogCreateAssociate.vue';
import {applicationStore} from '@/store/modules/application';
import DialogVideoCallFullScreen from '@/components/DialogVideoCallFullScreen.vue';
import DialogCreateCustomContact from '@/components/directory/DialogCreateCustomContact.vue';
import {directoryStore} from '@/store/modules/directory/directoryStore';
import {profileStore} from '@/store/modules/profile';
import ContainerCalc from '@/components/mixins/ContainerCalc';
import EmptyState from '@/components/EmptyState.vue';
import {mixins} from 'vue-class-component';
import {BusinessContact} from '@/domain/model/types';
import {tourStore} from '@/store/modules/tour';

@Component({
  name: 'contacts-list2',
  components: {
    DialogCreateCustomContact, EmptyState,
    DialogVideoCallFullScreen, DialogCreateAssociate, ContactListItem, SearchView, ListGroupItem
  }
})
export default class ContactsList2 extends mixins(ContainerCalc) {
  @Prop({default: 240}) width?: number | string;
  @Prop({default: 240}) maxWidth?: number | string;

  search: string = ''
  emptyIcon: string = ''
  emptyTitle: string = ''
  emptyDescription: string = ''

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get isAdmin() {
    return applicationStore.isAdmin;
  }

  get associateContacts() {
    return directoryStore.customContacts;
  }

  get associateGroupContacts() {
    return directoryStore.groupContacts;
  }

  get isEmpty() {
    return !this.groups.some((group) => group.items && group.items.length > 0)
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  // @Getter contactsOwners;
  // @Getter contactsAdmins;
  // @Getter associateContacts;
  // @Getter associateGroupContacts;

  showCreateContactDialog: boolean = false;

  // srcContactsOwners?: any[] = [];
  // srcContactsAdmins?: any[] = [];
  srcAssociateContacts?: any[] = [];
  srcAssociateGroupContacts?: any[] = [];

  private ASSOCIATE = 'Associate';
  private GROUP = 'Group';

  // @Watch('contactsOwners') onOwnersChanged(value, oldValue) {
  //     this.srcContactsOwners = Object.assign([], value);
  // }

  // @Watch('contactsAdmins') onAdminsChanged(value, oldValue) {
  //     this.srcContactsAdmins = Object.assign([], value);
  // }

  @Watch('associateContacts')
  onContactsChanged(value, oldValue) {
    this.srcAssociateContacts = Object.assign([], value);
  }

  @Watch('associateGroupContacts')
  onGroupContactsChanged(value, oldValue) {
    this.srcAssociateGroupContacts = Object.assign([], value);
  }

  get menu() {
    return [
      {
        title: this.ASSOCIATE
      },
      {
        title: this.GROUP
      }
    ];
  }

  get groups() {
    const {srcAssociateContacts, srcAssociateGroupContacts} = this;
    return [
      {
        title: 'contacts',
        items: srcAssociateContacts
      },
      {
        title: 'groups',
        items: srcAssociateGroupContacts
      }
    ];
  }

  get style() {
    return {
      width: `${this.width}px`,
      maxWidth: `${this.maxWidth}px`
    };
  }

  onSearch(term) {
    this.search = term
    if (!term) {
      // this.srcContactsOwners = Object.assign([], this.contactsOwners);
      // this.srcContactsAdmins = Object.assign([], this.contactsAdmins);
      this.srcAssociateContacts = Object.assign([], this.associateContacts);
      this.srcAssociateGroupContacts = Object.assign([], this.associateGroupContacts);
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.name.toLowerCase().includes(searchString)
        || (value.description && value.description.toLowerCase().includes(searchString))
        || (value.position && value.position.toLowerCase().includes(searchString));
    // this.srcContactsOwners = this.contactsOwners!!.filter(fFunc);
    // this.srcContactsAdmins = this.contactsAdmins!!.filter(fFunc);
    this.srcAssociateContacts = this.associateContacts!!.filter(fFunc);
    this.srcAssociateGroupContacts = this.associateGroupContacts!!.filter(fFunc);
  }

  onItemSelected(item) {
    try {
      console.log(`Contact has been selected: ${item.fullName}`);
    } catch (e: any) {
      this.$emit('error', e.message);
    }
  }

  onSelectContact(item: BusinessContact) {
    this.$router.push({name: 'contact-info', params: {contactId: item.id!}});
  }

  initEmpty() {
    const name = this.$route.name
    switch (name) {
      case 'directory':
        if (this.search) {
          this.emptyIcon = require('../assets/_empty_states_add_contact.svg')
          this.emptyTitle = 'No results found'
          this.emptyDescription = 'Try search again'
        } else {
          this.emptyIcon = require('../assets/_empty_states_add_contact.svg')
          this.emptyTitle = 'Business directory is empty'
          this.emptyDescription = 'Get started by creating contacts<br>' +
              'for your team members<br>' +
              'by pressing on plus icon'
        }
        break
    }
  }

  checkTour() {
    if (tourStore.tourNumber === 3 && profileStore.isAdmin && tourStore.tourAction) {
      this.showCreateContactDialog = true
    }
  }

  updated() {
    this.initEmpty()
    this.checkTour()
  }

  created() {
    console.log('ContactsList2:: created');
    // this.srcContactsOwners = Object.assign([], this.contactsOwners);
    // this.srcContactsAdmins = Object.assign([], this.contactsAdmins);
    this.srcAssociateContacts = Object.assign([], this.associateContacts);
    this.srcAssociateGroupContacts = Object.assign([], this.associateGroupContacts);
    this.initEmpty()
  }

  mounted() {
    this.calcHeight(this.$refs.contactListContainer)
  }

  beforeDestroy() {
    console.log('ContactsList2:: beforeDestroy');
  }
};
