
















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'contact-option-card'})
export default class ContactOptionCard extends Vue {
  @Prop() value!: boolean;
  @Prop() icon!: string;
  @Prop({default: ''}) type!: string;
  @Prop({default: 40}) width!: number | string;
  @Prop({default: 40}) height!: number | string;

  get selected() {
    return this.value;
  }

  set selected(value) {
    this.$emit('input', value);
  }
}
